import React, { FunctionComponent, useId, useMemo } from 'react';
import { LegacySearchIndexCourse } from '@shared/types';
import Link from 'next/link';
import Image from 'next/image';
import styles from './styles.module.scss';
import CourseDeliveryDetails from '../CourseDeliveryDetails';
import CourseTagLine from '../CourseTagline';
import StarRatingWithCount from '../StarRatingWithCount';
import { CoursePrice } from '../CoursePrice';
import { useCourseLink } from '../../hooks/useCourseLink';
import { useSavedCourse } from '../../hooks/useSavedCourse';
import { SaveableCourseOverlay } from '../SaveableCourseOverlay';
import { useIsEmbed } from '../../contexts/AppContext/useIsEmbed';

type CourseCardProps = {
    course: LegacySearchIndexCourse;
    hidePricing?: boolean;
    saved: boolean;
    showVATLabel?: boolean;
};

export const CourseCard: FunctionComponent<CourseCardProps> = ({
    course,
    hidePricing,
    saved: initialSaved,
    showVATLabel,
}) => {
    const id = useId();
    const courseLink = useCourseLink(course.educator_slug, course.slug);
    const { onSave, onUnsave, saved, loginModal } = useSavedCourse({
        courseId: course._id,
        saved: initialSaved,
    });
    const isEmbed = useIsEmbed();

    const content = useMemo(() => {
        return (
            <Link
                {...courseLink}
                className={styles.courseCardLink}
                aria-labelledby={`${id}-header`}
                aria-describedby={`${id}-details`}
            >
                <article
                    id={`${id}-card`}
                    className={styles.courseCard}
                    aria-labelledby={`${id}-header`}
                    aria-describedby={`${id}-details`}
                >
                    <div className={styles.cardCoverImage}>
                        <Image
                            src={course.image_url}
                            alt={`${course.name}`}
                            fill
                            sizes="(min-width: 1200px) 285px, (min-width: 1100px) 25vw,  (min-width: 768px) 33vw, (min-width: 530px) 50vw, 100vw"
                        />
                    </div>

                    <div className={styles.cardContent}>
                        <h3 id={`${id}-header`}>{course.name}</h3>

                        <div className={styles.cardDetails} id={`${id}-details`}>
                            <CourseDeliveryDetails course={course} />
                            <CourseTagLine course={course} />
                        </div>

                        <div className={styles.cardFooter}>
                            {course.reviews && course.reviews.count > 0 ? (
                                <StarRatingWithCount
                                    rating={course.reviews.rating}
                                    count={course.reviews.count}
                                />
                            ) : (
                                <span></span>
                            )}
                            {hidePricing ? null : (
                                <CoursePrice
                                    className={styles.cardPrice}
                                    course={course}
                                    showVATLabel={showVATLabel}
                                />
                            )}
                        </div>
                    </div>
                </article>
            </Link>
        );
    }, [course, hidePricing, id, courseLink, showVATLabel]);

    if (isEmbed) {
        return content;
    }

    return (
        <>
            <SaveableCourseOverlay
                className={styles.saveCourseOverlay}
                isSaved={saved}
                onSaved={onSave}
                onUnsaved={onUnsave}
            >
                {content}
            </SaveableCourseOverlay>
            {loginModal}
        </>
    );
};

export default CourseCard;
