import { EducatorProfileLinks } from '..';
import { AppContexts } from '../../../contexts/AppContext';

const getMarketplaceEducatorProfileLinks = (educatorSlug: string): EducatorProfileLinks => {
    return {
        coursesLink: `/${educatorSlug}/courses`,
        aboutLink: `/${educatorSlug}`,
        reviewsLink: `/${educatorSlug}/reviews`,
        enquiryLink: `/${educatorSlug}/enquiry`,
        membershipsLink: `/${educatorSlug}/memberships`,
        vouchersLink: null,
    };
};

const getMinisiteEducatorProfileLinks = (): EducatorProfileLinks => {
    return {
        coursesLink: `/`,
        aboutLink: `/about`,
        reviewsLink: `/reviews`,
        enquiryLink: `/enquiry`,
        membershipsLink: `/memberships`,
        vouchersLink: `/gift-checkout`,
    };
};

const getEmbedEducatorProfileLinks = (educatorSlug: string): EducatorProfileLinks => {
    return {
        coursesLink: `/embed/${educatorSlug}`,
        aboutLink: null,
        reviewsLink: `/embed/${educatorSlug}/reviews`,
        enquiryLink: `/embed/${educatorSlug}/enquiry`,
        membershipsLink: `/embed/${educatorSlug}/memberships`,
        vouchersLink: `/embed/${educatorSlug}/gift-checkout`,
    };
};

export const getEducatorProfileLinks = (
    context: AppContexts['context'],
    educatorSlug: string
): EducatorProfileLinks => {
    switch (context) {
        case 'marketplace':
            return getMarketplaceEducatorProfileLinks(educatorSlug);
        case 'minisite':
            return getMinisiteEducatorProfileLinks();
        case 'embed':
            return getEmbedEducatorProfileLinks(educatorSlug);
    }
};
