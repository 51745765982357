import classnames from 'classnames';
import { FunctionComponent, HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { FocusTrap } from '../FocusTrap';
import Portal from '../Portal';
import styles from './styles.module.scss';

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
    size: 'extra-small' | 'small' | 'medium' | 'large';
    onClose: () => void;
    closeOnBackdropClick?: boolean;
};

export const Modal: FunctionComponent<ModalProps> = ({
    size,
    onClose,
    closeOnBackdropClick = true,
    className,
    ...divProps
}) => {
    const modalWasMouseDown = useRef<boolean>(false);
    const modalContent = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const onKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [onClose]);

    const onMouseDown = useCallback(() => {
        modalWasMouseDown.current = true;
    }, []);

    useEffect(() => {
        if (closeOnBackdropClick === false) {
            return;
        }
        const onMouseUp = (event: MouseEvent) => {
            if (modalWasMouseDown.current === true) {
                modalWasMouseDown.current = false;
                return;
            }
            if (event.target instanceof Node && modalContent.current?.contains(event.target)) {
                return;
            }
            onClose();
        };
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [onClose, closeOnBackdropClick]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);

    return (
        <Portal>
            <FocusTrap>
                <div className={styles.container}>
                    <div
                        className={classnames(styles.modal, styles[size], className)}
                        role="dialog"
                        aria-modal="true"
                        onMouseDown={onMouseDown}
                        ref={modalContent}
                        {...divProps}
                    />
                </div>
            </FocusTrap>
        </Portal>
    );
};

export default Modal;
