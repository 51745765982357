'use client';

import {
    FunctionComponent,
    HTMLAttributes,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import BlueprintIcon from '../BlueprintIcon';

export type SnapScrollerListProps = {
    children: Array<ReactNode>;
} & HTMLAttributes<HTMLUListElement>;

export const SnapScrollerList: FunctionComponent<SnapScrollerListProps> = ({
    children,
    className,
    ...ulProps
}) => {
    const listRef = useRef<HTMLUListElement | null>(null);
    const [isOnFirstItem, setIsOnFirstItem] = useState(true);
    const [isOnLastItem, setIsOnLastItem] = useState(false);
    const [hasScroll, setHasScroll] = useState(true);

    const onScroll = useCallback((event: React.UIEvent<HTMLUListElement, UIEvent>) => {
        setIsOnFirstItem(Math.floor(event.currentTarget.scrollLeft) === 0);
        setIsOnLastItem(
            event.currentTarget.scrollLeft + event.currentTarget.clientWidth >=
                event.currentTarget.scrollWidth
        );
    }, []);

    const onPreviousClick = useCallback(() => {
        if (!listRef.current) {
            return;
        }
        listRef.current.scrollTo({
            behavior: 'smooth',
            left: Math.max(listRef.current.scrollLeft - listRef.current.clientWidth, 0),
            top: 0,
        });
    }, []);

    const onNextClick = useCallback(() => {
        if (!listRef.current) {
            return;
        }
        listRef.current.scrollTo({
            behavior: 'smooth',
            left: Math.min(
                listRef.current.scrollLeft + listRef.current.clientWidth,
                listRef.current.scrollWidth
            ),
            top: 0,
        });
    }, []);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }
        if (listRef.current.scrollWidth <= listRef.current.clientWidth) {
            setHasScroll(false);
        }
    }, [children]);

    return (
        <div
            className={classnames(styles.container, className, {
                [styles.noScroll]: hasScroll === false,
            })}
        >
            {hasScroll && !isOnFirstItem ? (
                <button
                    title="Previous item"
                    type="button"
                    className={styles.previousButton}
                    disabled={isOnFirstItem}
                    onClick={onPreviousClick}
                >
                    <BlueprintIcon icon="ArrowLeft" />
                </button>
            ) : null}
            {hasScroll && !isOnLastItem ? (
                <button
                    title="Next item"
                    type="button"
                    className={styles.nextButton}
                    disabled={isOnLastItem}
                    onClick={onNextClick}
                >
                    <BlueprintIcon icon="ArrowRight" />
                </button>
            ) : null}
            <ul className={styles.list} ref={listRef} onScroll={onScroll} {...ulProps}>
                {children.map((child, index) => {
                    return <li key={index}>{child}</li>;
                })}
            </ul>
        </div>
    );
};
