import { VatSettings } from '@shared/types';
import { useMemo } from 'react';
import { getVATEnabledAndPassed } from '../../services/getVATEnabledAndPassed';

export const useShowVATLabel = (educatorVATSettings?: VatSettings): boolean => {
    const showVATLabel: boolean = useMemo(() => {
        return getVATEnabledAndPassed(educatorVATSettings);
    }, [educatorVATSettings]);

    return showVATLabel;
};
