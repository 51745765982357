export type HexColour = `#${string}`;

export const stringToColour = (string: string): HexColour => {
    const colours: Array<HexColour> = [
        '#3366FF',
        '#52BD94',
        '#FFB020',
        '#D14343',
        '#897AE3',
        '#25CBD6',
        '#ED55C2',
        '#DE7548',
        '#9DB5FF',
        '#D6E0FF',
        '#EBF0FF',
        '#A3E6CD',
        '#DCF2EA',
        '#EEF8F4',
        '#FFD079',
        '#FFDFA6',
        '#FFEFD2',
        '#EE9191',
        '#F4B6B6',
        '#F9DADA',
        '#B8AFEE',
        '#D0CAF4',
        '#E7E4F9',
        '#7CE0E6',
        '#A8EAEF',
        '#D3F5F7',
        '#F499DA',
        '#F8BBE7',
        '#FBDDF3',
        '#EBAC91',
        '#F2C8B6',
        '#F8E3DA',
    ];
    let hash = 0;
    if (string.length === 0) return colours[hash];
    for (var i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colours.length) + colours.length) % colours.length;
    return colours[hash];
};

export const coloursToBackgroundGradient = (
    colour: HexColour,
    complimentColour: HexColour
): string => {
    return `linear-gradient(to top right, ${colour}, ${complimentColour})`;
};
