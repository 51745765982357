import { FunctionComponent } from 'react';
import htmlParser, { Element, HTMLReactParserOptions } from 'html-react-parser';
import parseStyles from 'style-to-js';

export const DEFAULT_PARSER_OPTIONS: HTMLReactParserOptions = {
    replace: (domNode) => {
        if (domNode instanceof Element && domNode.name === 'img') {
            const originalStyle = domNode.attribs['style'];
            const jsStyles = parseStyles(originalStyle);
            return (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    src={domNode.attribs.src}
                    loading="lazy"
                    style={jsStyles}
                    alt={domNode.attribs.alt || 'course image'}
                />
            );
        }
        if (domNode instanceof Element && domNode.name === 'a') {
            domNode.attribs.rel = 'noopener noreferrer nofollow';
            return domNode;
        }
        if (domNode instanceof Element && domNode.name === 'br') {
            return <br />;
        }
    },
};

export type ReactHTMLProps = {
    html: string;
    parserOptions?: HTMLReactParserOptions;
};

export const ReactHTML: FunctionComponent<ReactHTMLProps> = ({
    html,
    parserOptions = DEFAULT_PARSER_OPTIONS,
}) => {
    return <>{htmlParser(html, parserOptions)}</>;
};

export default ReactHTML;
