'use client';

import { QueryFunction, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { GetSavedCourseStatusByCourseIdResponse200 } from '@cademy-package/client-sdk';
import { CademyError } from '@shared/domain-shared';
import { CademyAPI } from '../../services/CademyAPI';
import { useIam } from '../../services/IAm/hooks';

export type ReturnType = GetSavedCourseStatusByCourseIdResponse200['body'];

const queryKey = 'GET /student/me/saved-courses/by-course-id';
type QueryKeys = [typeof queryKey, string[]];

type GetOptions = {
    courseIds: string[];
};

export const get = async ({ courseIds }: GetOptions): Promise<ReturnType> => {
    const response = await CademyAPI('GetSavedCourseStatusByCourseId')({
        queryParams: { courseIds: courseIds.join(',') },
    });

    if (response.statusCode === 200) {
        return response.body;
    }

    throw CademyError.fromUnknown(response.body);
};

const getQueryFunction: QueryFunction<ReturnType, QueryKeys, string> = async ({ queryKey }) => {
    return get({ courseIds: queryKey[1] });
};

export type UseSavedCoursesStateOptions = Omit<
    UseQueryOptions<ReturnType, unknown, ReturnType, QueryKeys>,
    'queryKey' | 'queryFn' | 'initialData' | 'enabled'
>;

export type UseSavedCoursesStateProps = {
    courseIds: string[];
};

export const useSavedCoursesState = (
    { courseIds }: UseSavedCoursesStateProps,
    options?: UseSavedCoursesStateOptions
) => {
    const { iam, iamReady } = useIam();
    const queryKey: QueryKeys = useMemo(() => {
        return ['GET /student/me/saved-courses/by-course-id', courseIds];
    }, [courseIds]);

    const result = useQuery({
        queryKey,
        queryFn: getQueryFunction,
        initialData: {},
        enabled: iamReady && iam.authenticated,
        ...options,
    });

    return result;
};
