import { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { HeartIcon } from '../Icons';

export type SaveableCourseOverlayProps = PropsWithChildren<{
    isSaved: boolean;
    onSaved: () => void;
    onUnsaved: () => void;
    className?: string;
}>;

export const SaveableCourseOverlay: FunctionComponent<SaveableCourseOverlayProps> = ({
    isSaved,
    onSaved,
    onUnsaved,
    className,
    children,
}) => {
    const onButtonClick = useCallback(() => {
        if (isSaved === true) {
            return onUnsaved();
        }
        return onSaved();
    }, [isSaved, onSaved, onUnsaved]);

    return (
        <div
            className={classNames(styles.overlayWrapper, className)}
            data-saved={isSaved || undefined}
        >
            {children}
            <button className={styles.saveButton} onClick={onButtonClick}>
                <HeartIcon viewBox="-1 0 18 16" />
            </button>
        </div>
    );
};
