import { FunctionComponent, ReactNode, useEffect, useRef } from 'react';

const isNotDisabled = (element: HTMLElement): boolean => {
    return element.hasAttribute('disabled') === false;
};

const isNotAriaHidden = (element: HTMLElement): boolean => {
    return (
        element.hasAttribute('disabled') === false || element.getAttribute('aria-hidden') !== 'true'
    );
};

const getKeyboardFocusableElements = (element: HTMLElement): Array<HTMLElement> => {
    // Based on https://zellwk.com/blog/keyboard-focusable-elements/
    const potentiallyFocusableElements = Array.from(
        element.querySelectorAll(
            'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
        )
    ) as Array<HTMLElement>;
    return potentiallyFocusableElements.filter(isNotDisabled).filter(isNotAriaHidden);
};

type FocusTrapProps = {
    active?: boolean;
    children: ReactNode | Array<ReactNode>;
};

export const FocusTrap: FunctionComponent<FocusTrapProps> = ({ children, active = true }) => {
    const focusTrapRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (active === true && focusTrapRef.current) {
            const onKeyDown = (event: KeyboardEvent) => {
                if (event.key !== 'Tab') {
                    return;
                }
                if (!focusTrapRef.current) {
                    return;
                }
                const focusableElements = getKeyboardFocusableElements(focusTrapRef.current);
                const firstElement = focusableElements[0];
                const lastElement = focusableElements[focusableElements.length - 1];
                const isTabbingForward = event.shiftKey !== true;
                if (isTabbingForward && document.activeElement === lastElement) {
                    if (firstElement) {
                        firstElement.focus();
                    }
                    return event.preventDefault();
                }
                if (isTabbingForward === false && document.activeElement === firstElement) {
                    if (lastElement) {
                        lastElement.focus();
                    }
                    return event.preventDefault();
                }
            };
            document.addEventListener('keydown', onKeyDown);
            return () => {
                document.removeEventListener('keydown', onKeyDown);
            };
        }
    }, [active]);

    useEffect(() => {
        if (active === true && focusTrapRef.current) {
            const focusableElements = getKeyboardFocusableElements(focusTrapRef.current);
            const firstElement = focusableElements[0];
            if (firstElement) {
                firstElement.focus();
            }
        }
    }, [active]);

    return <div ref={focusTrapRef}>{children}</div>;
};
