'use client';
import { useMemo } from 'react';
import { getEducatorProfileLinks } from './getEducatorProfileLinks';
import { useAppContext } from '../../contexts/AppContext/useAppContext';

export type EducatorProfileLinks = {
    coursesLink: string;
    aboutLink: string | null;
    reviewsLink: string;
    enquiryLink: string;
    membershipsLink: string | null;
    vouchersLink: string | null;
};

export const useEducatorProfileLinks = (educatorSlug: string): EducatorProfileLinks => {
    const context = useAppContext();
    const educatorProfileLinks = useMemo(() => {
        return getEducatorProfileLinks(context, educatorSlug);
    }, [context, educatorSlug]);
    return educatorProfileLinks;
};
