import { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type PortalProps = {
    children: ReactNode;
};

export const Portal: FunctionComponent<PortalProps> = ({ children }) => {
    const portalRef = useRef<HTMLDivElement | null>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const container = document.createElement('div');
        document.body.appendChild(container);
        portalRef.current = container;
        setIsReady(true);
        return () => {
            document.body.removeChild(container);
            portalRef.current = null;
        };
    }, []);

    if (isReady === false || !portalRef.current) {
        return null;
    }
    return createPortal(children, portalRef.current);
};

export default Portal;
