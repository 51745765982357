import { List } from '@shared/types';
import classnames from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import Image from 'next/image';
import { coloursToBackgroundGradient, stringToColour } from '../../services/colours';
import styles from './styles.module.scss';

export type ListCardProps = {
    list: List;
    preset?: 'grid' | 'carousel';
} & HTMLAttributes<HTMLDivElement>;

export const ListCard: FunctionComponent<ListCardProps> = ({
    list,
    preset,
    className,
    ...divProps
}) => {
    const gradientColour1 = stringToColour(list.name);
    const gradientColour2 = stringToColour(list._id);
    const gradient = coloursToBackgroundGradient(gradientColour1, gradientColour2);
    return (
        <div
            className={classnames(
                preset === 'grid' ? styles.containerGridPreset : styles.containerDefault,
                className
            )}
            {...divProps}
        >
            {list.image_url ? (
                <div className={styles.imageBox}>
                    <Image
                        src={list.image_url}
                        alt={list.name}
                        fill
                        sizes={preset === 'grid' ? '400px' : '42px'}
                    />
                </div>
            ) : (
                <div className={styles.colourBox} style={{ backgroundImage: gradient }}></div>
            )}
            <span className={styles.label}>{list.name}</span>
        </div>
    );
};
