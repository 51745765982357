import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Modal from '../Modal';
import Button from '../Button';
import { CloseSolidIcon } from '../Icons';
import { Login } from '../Login';

export type LoginModalProps = {
    visible: boolean;
    onClose: () => void;
    onAuthenticated: () => void;
    message?: string;
};

const LoginModal: FunctionComponent<LoginModalProps> = ({
    visible,
    onClose,
    onAuthenticated,
    message,
}) => {
    if (!visible) {
        return null;
    }

    return (
        <Modal
            className={styles.modal}
            size="medium"
            onClose={onClose}
            closeOnBackdropClick={false}
        >
            <Button className={styles.closeButton} onClick={onClose}>
                <CloseSolidIcon />
            </Button>

            <Login message={message} onAuthenticated={onAuthenticated} />
        </Modal>
    );
};

export default LoginModal;
