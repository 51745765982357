import calculatePercentage from '../../services/numbers/calculatePercentage';
import { StarIcon } from '../Icons';
import styles from './StarRatingWithCount.module.scss';
import classNames from 'classnames';

export type StarRatingWithCountProps = {
    rating: number;
    count: number;
    className?: string;
};

const StarRatingWithCount = ({ rating, count, className }: StarRatingWithCountProps) => {
    return (
        <span className={classNames([styles.starRatingContainer, className])}>
            <StarIcon className={styles.star} fillPercentage={calculatePercentage(rating, 5)} />
            <span className={styles.rating}>{rating.toFixed(1)}</span>
            <span className={styles.count}>({count})</span>
        </span>
    );
};

export default StarRatingWithCount;
