import { useMemo } from 'react';
import { AppContexts, MarketplaceAppContext, MinisiteAppContext } from '../../contexts/AppContext';
import { useAppContext } from '../../contexts/AppContext/useAppContext';

export type ReturnContexts = MarketplaceAppContext['context'] | MinisiteAppContext['context'];

export const flattenMinisiteAndEmbedContext = (
    contexts: AppContexts['context']
): ReturnContexts => {
    switch (contexts) {
        case 'embed':
        case 'minisite':
            return 'minisite';
        default:
            return contexts;
    }
};

export const useCourseVisibility = (): ReturnContexts => {
    const appContext = useAppContext();
    const courseVisibility = useMemo(() => {
        return flattenMinisiteAndEmbedContext(appContext);
    }, [appContext]);

    return courseVisibility;
};
